import {ScreenVariations} from "../../library/context/screenview"
import { ChatMessageCardProps } from "./Chat";

export type InitialMessage = {
    [key in ScreenVariations]: ChatMessageCardProps;
};

export const initialMessage: InitialMessage = {
    handyCookGuide: {
        id: 'initial_message',
        role: 'assistant',
        content: 'I can help you with on-the-fly cooking advice or help making complex recipes simpler.'
    },
    convertRecipe: {
        id: 'initial_message',
        role: 'assistant',
        content: 'I can help you convert recipes intended for built-in ovens to use on Breville ovens. You can do this either by sending me a recipe URL or mention the type of Breville or ChefSteps appliance you have.'
    },
    ingredientsSwap: {
        id: 'initial_message',
        role: 'assistant',
        content: 'Missing some ingredients? I can help with ingredient subs and recipe adjustments based on your dietary preferences and restrictions.'
    },
    tipsAndClasses: {
        id: 'initial_message',
        role: "assistant",
        content: `Got any questions about your Breville/ChefSteps appliances? Here to help you go from curious to confident in the kitchen.`
    },
    guideAndTools: {
        id: 'initial_message',
        role: "assistant",
        content: `I’m here to help with everything you need to level up in the kitchen. Tips, tricks, techniques, and more.`
    },
    recipeIdeas: {
        id: 'initial_message',
        role: 'assistant',
        content: 'Looking for new recipe ideas? I can suggest some based on your preferences.'
    },
    cookingTechniques: {
        id: 'initial_message',
        role: 'assistant',
        content: 'Want to learn new cooking techniques? I can guide you through various methods.'
    },
    kitchenHacks: {
        id: 'initial_message',
        role: 'assistant',
        content: 'Need some kitchen hacks? I have tips and tricks to make your cooking easier.'
    },
    mealPrep: {
        id: 'initial_message',
        role: 'assistant',
        content: 'Planning your meals? I can help you with meal prep ideas and tips.'
    },
    nutritionTips: {
        id: 'initial_message',
        role: 'assistant',
        content: 'Looking for nutrition tips? I can provide advice on healthy eating.'
    }
}