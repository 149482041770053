import React from 'react';
import { Trail } from "../components/Trial";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Video from '../assets/Video.svg';
import Microwave from '../assets/microwave.svg';
import MicrowaveSouvide from '../assets/microwave-souvide.svg';
import GuidanceTips from '../assets/guidance-tips.svg';
import BrevillePlusLogo from '../assets/logo.svg';
import './scss/choice.scss';
import { ReactNode } from "react";
import { ScreenVariations, useScreenContext } from "../library/context/screenview";

// Shuffle function to randomize array order
function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function ChoiceCard(
    props: {
        logo: ReactNode,
        title: ReactNode | string,
        screenName: ScreenVariations,
        helperText?: string,
        disabled?: boolean
    }){

    const {setCurrentScreen} = useScreenContext();
    return(
        <Card
            body
            className={`mt-3 choice-card ${props.disabled ? 'disabled' : '' }`}
            onClick={()=>{
                if(props.disabled) return;
                setCurrentScreen(props.screenName)
            }}
        >
            <div className="choice-card-body">
                <div>{props.logo}</div>
                <div>{props.title}</div>
                {props.helperText && <p>{props.helperText}</p>}
            </div>
        </Card>
    )
}

export function Choice(){

    const {appType} = useScreenContext();

    const isWidget = appType === 'widget';

    // Array of card data
    const cardData = [
        { screenName: "handyCookGuide", logo: <MicrowaveSouvide />, title: "Handy Cook Guide" },
        { screenName: "convertRecipe", logo: <Microwave />, title: "Convert Recipe" },
        { screenName: "ingredientsSwap", logo: <GuidanceTips />, title: "Ingredients Swap" },
        { screenName: "tipsAndClasses", logo: <Video />, title: "Tips and Classes" },
        { screenName: "guideAndTools", logo: <Video />, title: "Guide and Tools" },
        { screenName: "recipeIdeas", logo: <Microwave />, title: "Recipe Ideas" },
        { screenName: "cookingTechniques", logo: <MicrowaveSouvide />, title: "Cooking Techniques" },
        { screenName: "kitchenHacks", logo: <GuidanceTips />, title: "Kitchen Hacks" },
        { screenName: "mealPrep", logo: <Video />, title: "Meal Prep" },
        { screenName: "nutritionTips", logo: <Microwave />, title: "Nutrition Tips" }
    ];

    // Shuffle the card data
    const shuffledCardData = shuffleArray(cardData);

    // Show only the first four choices
    const displayedCardData = shuffledCardData.slice(0, 4);

    return(
        <div className={`screen-choice-block ${isWidget ? 'screen-choice-block-widget' : ''}`}>
            <div className="choice-block-logo mb-4"><BrevillePlusLogo/></div>
            <Trail open={true}>
                <Row>
                    {displayedCardData.map((card, index) => (
                        <Col key={index} lg={isWidget ? 12 : 6} sm={12}>
                            <ChoiceCard screenName={card.screenName} logo={card.logo} title={card.title} helperText={card.helperText} />
                        </Col>
                    ))}
                </Row>
            </Trail>
        </div>
    )

}